/* Basic editor styles */
.notedEditorContainer {
  // min-height: 300px;
  // max-height: 500px;
  // overflow: auto;
  // width: 450px;
  // margin: 0 auto;
  outline: none;
  // padding: 8px;
  // background-color: #f1f1f1;
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1.5rem;
  }
}

/* Placeholder (at the top) */
/*.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}*/

/* Placeholder (on every new line) */
.notedEditorContainer .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
